import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import { NewAppButton, NewAppText, NewAppTitle } from '../ui';
import { laptopSize, mobileSize } from '../../utils/';
import { theme } from '../../utils/theme';

const LetsConnectSection = () => {
  return (
    <LetsConnectDiv>
      <TextContainer>
        <LetsConnectText>Let's Connect</LetsConnectText>
        <LetsConnectDescription>
          Ready to transform your idea into a digital product people love to use? <br /> Let's make
          it happen.
        </LetsConnectDescription>
        <CenterDiv>
          <Link to="/contact-us/">
            <ContactUsButton>Contact Us</ContactUsButton>
          </Link>
        </CenterDiv>
      </TextContainer>
    </LetsConnectDiv>
  );
};

const TextContainer = styled.div`
  width: 835px;
  z-index: 2;
  @media ${laptopSize} {
    width: 100%;
  }
`;

const LetsConnectDiv = styled.div`
  background-color: ${theme.MID_BLUE};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 62px 20px;
`;

const ContactUsButton = styled(NewAppButton)`
  margin: 0px;
  padding: 10px 12px;
  line-height: 1;
  height: 40px;
  background-color: ${theme.MAIN_DARK_BLUE};
  color: ${theme.WHITE_COLOR};
  border-radius: 7px;
  border: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
`;

const CenterDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const LetsConnectDescription = styled(NewAppText)`
  font-weight: 400;
  font-size: 21px;
  line-height: 34px;
  text-align: center;
`;

const LetsConnectText = styled(NewAppTitle)`
  font-weight: 700;
  font-size: 42px;
  line-height: 55px;
  text-align: center;
  @media ${mobileSize} {
    font-size: 35px;
    line-height: 45px;
  }
`;

export default LetsConnectSection;
